import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import BlockHeader from '@/components/BlockHeader';
import SimpleGrid from '@/components/SimpleGrid';
import DownloadButton from '@/components/DownloadButton';
import Grid from '@/components/ui/Grid';

const PdfDownloadBlock = createBlock<'blocks_pdfDownload_BlockType'>(
  ({ _blockMeta: meta, anchor, heading, content, documentMultiple = [] }) => {
    return (
      <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <BlockHeader
              customAnchor={anchor}
              heading={heading}
              HeadingProps={{ variant: 'h4' }}
              content={content}
              maxWidth="none"
            />
            <SimpleGrid cx={{ mT: 'xl' }}>
              {documentMultiple.map((document) => (
                <DownloadButton key={document?.id} document={document} />
              ))}
            </SimpleGrid>
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default PdfDownloadBlock;
