import React from 'react';
import type { UseFormReturn } from 'react-hook-form';

type Compare = '=' | '!=' | '>' | '<' | 'contains' | 'startsWith' | 'endsWith';

type ConditionField = string;

interface Condition {
  id: string;
  field: ConditionField;
  condition: Compare;
  value: string;
}

interface FieldCondition {
  showRule: 'show' | 'hide';
  conditionRule: 'all' | 'any';
  conditions: Condition[];
}

const parseFieldHandle = (field?: string | null): ConditionField => {
  const fieldHandle: ConditionField = field ? /{field:(.*)}/.exec(field)?.[1] || '' : '';
  return fieldHandle;
};

const parseFieldCondition = (condition?: unknown): FieldCondition | null => {
  try {
    const maybeCondition =
      condition && typeof condition === 'string' ? JSON.parse(condition) : null;

    if (!maybeCondition) {
      return null;
    }

    const maybeConditions = Array.isArray(maybeCondition.conditions)
      ? maybeCondition.conditions
      : [];

    const fieldCondition: FieldCondition = {
      showRule: maybeCondition.showRule === 'show' ? 'show' : 'hide',
      conditionRule: maybeCondition.conditionRule === 'all' ? 'all' : 'any',
      conditions: maybeConditions.map((condition: Condition) => {
        return {
          id: condition.id,
          field: parseFieldHandle(condition.field),
          condition: condition.condition,
          value: condition.value,
        };
      }),
    };

    return fieldCondition;
  } catch (e) {
    console.error('Error parsing condition', condition);
    return null;
  }
};

type FormValue = string | string[];

const isFormValue = (value: unknown): value is FormValue => {
  return typeof value === 'string' || Array.isArray(value);
};

const isRecord = (value: unknown): value is Record<string, unknown> => {
  return typeof value === 'object' && value !== null;
};

const extractFieldValue = (path: string, formValues: unknown): FormValue => {
  const keys = path.split('.');

  let value: FormValue = '';

  keys.reduce((acc, key, i) => {
    if (!isRecord(acc)) return undefined;

    const isLastKey = i === keys.length - 1;
    const thisValue = acc[key];

    if (isLastKey && isFormValue(thisValue)) {
      value = thisValue;
      return thisValue;
    }

    return acc[key];
  }, formValues as unknown);

  return value;
};

const testCondition = (condition: Condition, formValues: unknown): boolean => {
  const fieldValue = extractFieldValue(condition.field, formValues);

  const fieldValueArr = Array.isArray(fieldValue) ? fieldValue : [fieldValue];
  const fieldValueStrArr: string[] = fieldValueArr.map((value) => value.toString());
  const value = typeof condition.value === 'string' ? condition.value : '';
  const numberValue = parseFloat(value);
  const fieldValueFloat = typeof fieldValue === 'string' ? parseFloat(fieldValue) : 0;

  switch (condition.condition) {
    case '=':
      // console.log('fieldValue', fieldValue, '=', value);
      return fieldValue === value;
    case '!=':
      // console.log('fieldValue', fieldValue, '!=', value);
      return fieldValue !== value;
    case '>':
      // console.log('fieldValue', fieldValueFloat, '>', numberValue);
      return fieldValueFloat > numberValue;
    case '<':
      // console.log('fieldValue', fieldValueFloat, '<', numberValue);
      return fieldValueFloat < numberValue;
    case 'contains':
      // console.log('fieldValue', fieldValueStrArr, 'contains', value);
      return (
        fieldValueStrArr.includes(value) ||
        !!fieldValueStrArr.find((v) => v.toLowerCase().includes(value.toLowerCase()))
      );
    case 'startsWith':
      // console.log('fieldValue', fieldValueStrArr, 'startsWith', value);
      return !!fieldValueStrArr.find((v) => v.toLowerCase().startsWith(value.toLowerCase()));
    case 'endsWith':
      // console.log('fieldValue', fieldValueStrArr, 'endsWith', value);
      return !!fieldValueStrArr.find((v) => v.toLowerCase().endsWith(value.toLowerCase()));
    default:
      console.error('Unknown condition', condition);
      return false;
  }
};

export default function useFieldConditions(
  conditionString?: string,
  enabled?: boolean,
  methods?: UseFormReturn
) {
  const fieldCondition = React.useMemo(
    () => parseFieldCondition(conditionString || ''),
    [conditionString]
  );

  if (!enabled || !fieldCondition || !methods) {
    return true;
  }
  const formValues = methods.watch();

  const { conditions, conditionRule } = fieldCondition;

  if (conditions.length === 0) {
    return true;
  }

  const conditionResults = conditions.map((condition) => testCondition(condition, formValues));
  // console.log('formValues', formValues, 'conditionResults', conditionResults);

  if (conditionRule === 'all') {
    return conditionResults.every((result) => result);
  }

  return conditionResults.some((result) => result);
}
