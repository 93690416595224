'use client';

import { fmtCategoryNames } from '@/lib/utils/format';
import Card from '../Card';
import Divider from '../ui/Divider';
import { EntryCardProps } from './EntryCardProps';

export type ListCardProps = EntryCardProps<'article'>;

export const ListCard = ({ item, ...props }: ListCardProps) => {
  const {
    uri: href,
    title,
    pageTitle,
    summary: description,
    postDate,
    contentTypeCategorySingle,
  } = item;

  const contentTypeName = fmtCategoryNames(contentTypeCategorySingle);

  return (
    <Card
      elevation={false}
      colorSet="white"
      paper
      {...props}
      item={{
        href,
        title: pageTitle ?? title,
        meta: [contentTypeName, postDate],
        description,
      }}>
      <Card.Body cx={{ rowGap: 'xs', mY: 'xs' }}>
        <Card.Meta variant="small" />
        <Card.Title variant="h5" as="span" />
        <Card.Cta
          variant="text"
          size="small"
          EndIconProps={{ name: 'arrowRight', cx: { color: 'accent' } }}
          cx={{ fontSize: 'medium' }}
        />
      </Card.Body>
      <Divider direction="horizontal" cx={{ color: 'neutralLight' }} />
    </Card>
  );
};
