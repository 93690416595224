import Card from '@/components/Card';
import { CardBase } from '@/components/Card/useCard';
import Box from '@/components/ui/Box';
import { createBlock } from '@/lib/parsers/blocks';

export const ExternalCardBlock = createBlock<'blocks_externalCard_BlockType'>(
  ({ imageSingle, heading, content, linkField }) => {
    const item: CardBase = {
      title: heading,
      description: content,
      image: imageSingle,
      href: linkField?.href,
      customText: linkField?.text,
    };
    const newWindow = linkField?.target === '_blank';

    return (
      <Card
        {...{
          fullHeight: true,
          elevation: false,
          padding: 'sm',
          item,
          newWindow,
        }}>
        <Box cx={{ pX: 'sm', pT: 'sm' }}>
          <Card.Image ratio="landscape" transform="fullFit" />
        </Box>
        <Card.Body>
          <Card.Title variant="h4" cx={{ mB: 'none' }} />
          <Card.Description />
        </Card.Body>
        <Card.Foot cx={{ mB: 'sm' }}>
          <Card.Cta
            variant="text"
            size="normal"
            EndIconProps={{ name: 'arrowRight', cx: { color: 'accent' } }}
          />
        </Card.Foot>
      </Card>
    );
  }
);

export default ExternalCardBlock;
