'use client';

import classNames from 'classnames';
import React from 'react';
import Box, { BoxProps } from '../ui/Box';
import UnstyledAccordionItemBody, {
  UnstyledAccordionItemBodyProps,
} from './UnstyledAccordion.ItemBody';
import UnstyledAccordionItemCaret, {
  UnstyledAccordionItemCaretProps,
} from './UnstyledAccordion.ItemCaret';
import UnstyledAccordionItemHandle, {
  UnstyledAccordionItemHandleProps,
} from './UnstyledAccordion.ItemHandle';
import UnstyledAccordionItemLabel, {
  UnstyledAccordionItemLabelProps,
} from './UnstyledAccordion.ItemLabel';
import * as styles from './UnstyledAccordion.css';
import { useUnstyledAccordionItem } from './useUnstyledAccordion';

export type UnstyledAccordionItemProps<C extends React.ElementType = 'div'> = Omit<
  BoxProps<
    C,
    {
      HandleProps?: UnstyledAccordionItemHandleProps;
      LabelProps?: UnstyledAccordionItemLabelProps;
      CaretProps?: UnstyledAccordionItemCaretProps;
      BodyProps?: UnstyledAccordionItemBodyProps;
      index: number;
    }
  >,
  'children'
>;

const UnstyledAccordionItem = ({
  index,
  HandleProps,
  LabelProps,
  CaretProps,
  BodyProps,
  className,
  ...props
}: UnstyledAccordionItemProps) => {
  const { isActive } = useUnstyledAccordionItem(index);

  //TODO: Optional callback here

  return (
    <Box
      className={classNames([
        styles.item({ itemFirst: index === 0, itemOpen: isActive }),
        className,
      ])}
      {...props}>
      <UnstyledAccordionItemHandle index={index} {...HandleProps}>
        <UnstyledAccordionItemLabel {...LabelProps} />
        <UnstyledAccordionItemCaret isActive={isActive} {...CaretProps} />
      </UnstyledAccordionItemHandle>
      <UnstyledAccordionItemBody index={index} {...BodyProps} />
    </Box>
  );
};

export default UnstyledAccordionItem;
