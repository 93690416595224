import { sanitiseAnything, SanitisedElement } from '@/lib/sanitise/sanitiseElements';
import { BlocksFragment } from '__generated__/graphql';

import { maybeGet, slugify, Typename } from '@liquorice/allsorts-craftcms-nextjs';
import { Entry, isEntry } from '../entries';

export type RawBlocks = BlocksFragment;

/**
 * __typename of top level Block
 */
export type BlockTypename = Typename<RawBlocks>;

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

export type SanitisedBlock<T extends BlockTypename = BlockTypename> = SanitisedElement<T>;
export type Block<T extends BlockTypename = BlockTypename> = SanitisedElement<T> & {
  _blockMeta?: BlockMeta;
};

export type BlockMeta = {
  typename: BlockTypename;
  level?: number;
  inView?: boolean;
  index: number;
  first: boolean;
  firstOfType: boolean;
  nthOfType: number;
  last: boolean;
  previousBlock?: Block;
  inTimeline?: boolean;
  firstInTimeline?: boolean;
  lastInTimeline?: boolean;
};

// ----------------------------------------------------------------------------------------------------

/**
 * Create a {@link Block} consumer React Component by providing
 * the `__typename` of `BlockType` as T
 */
export const createBlock = <T extends BlockTypename, P = NoProps, R = JSX.Element | null>(
  fn: (props: Partial<Block<T>> & P) => R
) => fn;

export const sanitiseBlocks = (maybeBlocks: MaybeArrayOf<BlocksFragment>) => {
  return sanitiseAnything.many(maybeBlocks) as SanitisedBlock[];
};

export const parseSanitisedBlocks = (sanitisedBlocks: SanitisedBlock[] = []): Block[] => {
  const nthOfTypeCount: Record<string, number> = {};

  return sanitisedBlocks
    .map((v, i): Block | null => {
      const type = v.__typename;
      // const nextBlock: Block | undefined = sanitisedBlocks[i + 1];
      nthOfTypeCount[type] = type in nthOfTypeCount ? nthOfTypeCount[type] + 1 : 0;

      return {
        ...v,
        _blockMeta: {
          typename: type,
          nthOfType: nthOfTypeCount[type],
          index: i,
          first: i === 0,
          firstOfType: !!sanitisedBlocks.find((x, j) => j < i && x.__typename === v.__typename),
          last: i === sanitisedBlocks.length - 1,
          // previousBlock: sanitisedBlocks[i - 1],
        },
      };
    })
    .filter(Boolean) as Block[];
};

export const parseBlocks = (maybeBlocks: MaybeArrayOf<BlocksFragment>): Block[] => {
  return parseSanitisedBlocks(sanitiseBlocks(maybeBlocks));
};

export type BlockView<T extends BlockTypename = BlockTypename> = {
  anchorLabel?: string;
  anchor?: string;
  block: Block<T>;
  _blockMeta?: BlockMeta;
};

const handleMediaReleaseStatement = (blocks: Block[], entry?: Entry): Block[] => {
  if (!isEntry(entry, 'article') || !entry.mediaRelease) return blocks;

  const hasMediaReleaseBlock = blocks.some(
    (block) => block.__typename === 'blocks_mediaReleaseStatement_BlockType'
  );

  if (hasMediaReleaseBlock) return blocks;

  const indexOfLastTextBlock = blocks.reduce((acc, block, index) => {
    return block.__typename === 'blocks_richText_BlockType' ? index : acc;
  }, -1);

  const mediaReleaseBlock: Block<'blocks_mediaReleaseStatement_BlockType'> = {
    __typename: 'blocks_mediaReleaseStatement_BlockType',
    id: 'media-release-statement',
  };

  // Insert media release statement block after the last text block, or at the end of the blocks array
  if (indexOfLastTextBlock === -1) {
    blocks.push(mediaReleaseBlock);
  } else {
    blocks.splice(indexOfLastTextBlock + 1, 0, mediaReleaseBlock);
  }

  return blocks;
};

export const filterBlocks = (blocks: Block[], entry?: Entry) => {
  // -------------------------------------------------------
  // ---- Handle media release statement blocks ----
  blocks = handleMediaReleaseStatement(blocks, entry);

  // Count instances of identical anchors
  const anchorCount: Record<string, number> = {};

  const anchors: [anchor: string, label: string][] = [];

  const filteredBlocks = blocks.map((block, blockKey) => {
    const heading = maybeGet(block, 'heading');
    const anchorRaw = slugify(heading ?? `block-${blockKey}`);

    anchorCount[anchorRaw] = anchorCount[anchorRaw] ? anchorCount[anchorRaw] + 1 : 1;

    const anchor =
      anchorCount[anchorRaw] > 1 ? `${anchorRaw}-${anchorCount[anchorRaw]}` : anchorRaw;

    if (heading) anchors.push([anchor, heading]);

    return {
      anchorLabel: heading,
      anchor,
      block,
    } as BlockView;
  });

  return {
    anchors,
    blocks: filteredBlocks,
  };
};

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBlock = <T extends BlockTypename>(x: any, typename: T): x is Block<T> => {
  return !!x && x.__typename === typename;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBlockView = <T extends BlockTypename>(x: any, typename: T): x is BlockView<T> => {
  return !!x && 'block' in x && x.block.__typename === typename;
};
