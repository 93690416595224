import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import Document from '@/components/Document';
import Grid from '@/components/ui/Grid';

const PdfEmbedBlock = createBlock<'blocks_pdfEmbed_BlockType'>(
  ({ _blockMeta: meta, anchor: customAnchor, documentSingle }) => {
    return (
      <BlockContainer
        {...{
          meta,
          customAnchor,
          marginY: true,
          disableTransition: true,
          BlockLinkProps: { GridColProps: { offsetMd: 2, md: 8 } },
        }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <Document document={documentSingle} />
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default PdfEmbedBlock;
