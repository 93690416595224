// import { fmtCategoryNames } from '@/lib/utils/format';
import { ImageEntry } from '@/lib/parsers/images';
import { fmtCategoryNames, fmtRole } from '@/lib/utils/format';
import DefaultImage from 'public/images/default-image.png';
import Card from '../Card';
import { EntryCardProps } from './EntryCardProps';
import { SITE_ENTITY } from '@/lib/constants';

export type PresenterCardProps = EntryCardProps<'profile'>;

export const PresenterCard = ({ item, ...props }: PresenterCardProps) => {
  const {
    uri: href,
    firstName,
    lastName,
    honorific,
    entryImage: image,
    profileRoleSingle,
    displayRole,
    displayRoleMode,
    socialLinks,
  } = item;

  const { src, width, height } = DefaultImage ?? {};
  const defaultImage = { src, width, height } as ImageEntry;

  const roleCategoryName = fmtCategoryNames(profileRoleSingle);
  const role = fmtRole(roleCategoryName, displayRoleMode, displayRole);

  return (
    <Card
      disableLinkWrap
      elevation={false}
      fullHeight
      colorSet="white"
      paper
      {...props}
      item={{
        href,
        honorific,
        firstName,
        lastName,
        image: image ?? defaultImage,
        meta: [role, SITE_ENTITY],
        linkedinUrl: socialLinks?.linkedin,
      }}>
      <Card.Image ratio="landscape" transform="fullFit" />
      <Card.Body cx={{ pT: 'md' }}>
        <Card.Title variant="h5" as="span" enableLinkWrap />
        <Card.Meta />
      </Card.Body>
      <Card.Foot cx={{ pT: '3xs' }}>
        <Card.Linkedin />
      </Card.Foot>
    </Card>
  );
};
