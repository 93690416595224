import Shim, { ShimProps } from '../Shim';
import { VideoPlayer } from './VideoPlayer';

export type VideoProps = ShimProps<
  'div',
  {
    videoUrl?: Maybe<string>;
    controls?: boolean;
    playing?: boolean;
    loop?: boolean;
    muted?: boolean;
  }
>;
const Video = ({
  videoUrl,
  controls = true,
  playing = false,
  loop = false,
  muted = false,
  ratio = 'video',
  ...props
}: VideoProps) => {
  return videoUrl ? (
    <Shim ratio={ratio} {...props}>
      <VideoPlayer
        {...{
          url: videoUrl,
          controls,
          playing,
          loop,
          muted,
        }}
      />
    </Shim>
  ) : (
    <></>
  );
};

export default Video;
