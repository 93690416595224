'use client';

import { FormErrors, FormValues } from '@/components/Formie/formTypes';
import {
  GenericFormSubmissionMutation,
  parseFormSubmissionErrors,
  parseSubmitFormQuery,
} from '@/lib/parsers/form';
import { FetchResult, HttpLink, isApolloError, useApolloClient } from '@apollo/client';
import React, { useCallback } from 'react';

type SubmitFormieFormResult = {
  errors?: FormErrors;
  result?: GenericFormSubmissionMutation | null;
  success?: boolean;
};

const genericErrorResponse = (message?: string): SubmitFormieFormResult => ({
  errors: { error: [message ?? 'An error occurred.'] },
  result: null,
  success: false,
});

/**
 * Recursively replace undefined values with null
 */
const nullifyEmptyValues = <T extends Record<string, unknown>>(values: T) => {
  return JSON.parse(JSON.stringify(values, (key, value) => value ?? null));
};

const FORMS_ENDPOINT = '/api/forms';

export default function useFormieSubmit() {
  const client = useApolloClient();

  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<FormErrors | null>(null);
  const [result, setResult] = React.useState<GenericFormSubmissionMutation | null>(null);

  const triggerSubmit = useCallback(
    async (mutationName: string, formValues: FormValues, formUid: string) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setErrors(null);
      setResult(null);

      client.setLink(new HttpLink({ uri: `${FORMS_ENDPOINT}/${formUid}/` }));

      const mutation = parseSubmitFormQuery({
        mutationName,
        formValues: nullifyEmptyValues(formValues),
      });

      if (!mutation) return genericErrorResponse();

      try {
        await client
          .mutate({
            mutation,
          })
          .then((res) => {
            const errors = parseFormSubmissionErrors(res);

            const maybeResult =
              (res as FetchResult<GenericFormSubmissionMutation> | null)?.data?.submission ?? null;
            const result = !errors && maybeResult ? maybeResult : null;
            const success = !!result;

            setIsLoading(false);
            setIsSuccess(success);
            setIsError(!success);
            setErrors(errors);
          })
          .catch((error) => {
            let formErrors: FormErrors = {};

            if (isApolloError(error)) {
              const { graphQLErrors = [] } = error;
              console.log('GraphQL Errors', graphQLErrors);
              formErrors = graphQLErrors.reduce((results, item) => {
                const { message } = item;

                try {
                  const parsedMessage = JSON.parse(message) as FormErrors;

                  return {
                    ...results,
                    ...parsedMessage,
                  };
                } catch (e) {
                  return {
                    ...results,
                    error: [message],
                  };
                }
              }, {} as FormErrors);
            } else {
              console.log('Unknown Request Error', error);
              formErrors = { error: ['An error occurred.'] };
            }

            setIsLoading(false);
            setIsError(true);
            setErrors(formErrors);
          });
      } catch (error) {
        console.error(error);

        setIsLoading(false);
        setIsError(true);
        setIsSuccess(false);
        setErrors({ error: ['An error occurred.'] });
      }
    },
    [client]
  );

  return {
    triggerSubmit,
    isSuccess,
    isError,
    isLoading,
    errors,
    result,
  };
}
