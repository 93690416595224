import { FormControl, FormHelperText, FormLabel } from '@/components/ui';

import FormieFieldRows from '@/components/Formie/FormieFieldRows';
import { createField } from '@/lib/parsers/form/createFieldControl';

export const Field_Address = createField<'Field_Address'>(({ field, methods }) => {
  const { nestedRows, handle, label, instructions, labelPosition } = field;

  // const { error, errors } = useFormieField(handle, 'complex', {});

  return (
    <FormControl /* error={error} */ fullWidth>
      <FormLabel component="legend" sx={{ mb: 2 }} hidden={labelPosition === 'hidden'}>
        {label}
      </FormLabel>

      {instructions && <FormHelperText>{instructions}</FormHelperText>}

      <FormieFieldRows rows={nestedRows} parentHandle={handle} methods={methods} />
    </FormControl>
  );
});
