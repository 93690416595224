import { DocumentAsset } from '@/lib/parsers/common';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import Icon from '../Icon';
import Box from '../ui/Box';
import { BtnProps } from '../ui/Btn';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './DownloadButton.css';

export type DownloadButtonProps = BtnProps & {
  title?: string;
  document?: Partial<DocumentAsset>;
};

const DownloadButton = ({ document = {}, title: customTitle }: DownloadButtonProps) => {
  const { url: href, extension, sizeHuman } = document;

  const title = customTitle ?? document.title;

  const ext = extension ? extension.toUpperCase() : null;
  const size = sizeHuman ?? null;
  const meta = makeNonNullableArray([ext, size]);

  const metaText = `(${meta.join(', ')})`.toUpperCase();
  const ariaLabel = `${title} ${metaText}`;

  return !href ? (
    <></>
  ) : (
    <Box className={styles.wrapper}>
      <Box
        as="a"
        download={'download'}
        aria-label={ariaLabel}
        href={href}
        target="_blank"
        className={styles.root}>
        <Flex
          direction="row"
          justifyContent="between"
          alignItems="center"
          colorSet="secondary"
          paper
          cx={{ pY: 'xs', pX: 'md' }}>
          <Txt as="span" variant="h5">
            {title}
          </Txt>
          <Icon name="download" cx={{ fontSize: 'h4' }} className={styles.icon} />
        </Flex>
        <Flex
          colorSet="primaryLight"
          paper
          direction="row"
          justifyContent="between"
          cx={{ pY: '3xs', pX: 'md' }}>
          <Txt as="span" variant="small">
            {ext}
          </Txt>
          <Txt as="span" variant="small">
            {size}
          </Txt>
        </Flex>
      </Box>
    </Box>
  );
};

export default DownloadButton;
