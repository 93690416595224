import { FieldErrors } from '@/components/Formie/Fields/FieldErrors';
import { createField } from '@/lib/parsers/form/createFieldControl';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useController } from 'react-hook-form';

export const Field_Radio = createField<'Field_Radio'>(({ field, methods }) => {
  const { label, handle, required, options, instructions, layout, labelPosition } = field;

  const {
    field: fieldControl,
    fieldState: { error },
  } = useController({
    name: handle,
    control: methods.control,
    defaultValue: '',
  });

  return (
    <FormControl required={required} error={!!error}>
      <FormLabel component="legend" sx={{ mb: 1 }} hidden={labelPosition === 'hidden'}>
        {label}
      </FormLabel>
      <FieldErrors errors={error} />
      <FormHelperText>{instructions}</FormHelperText>
      <RadioGroup
        {...{
          ...fieldControl,
          row: layout === 'horizontal',
          sx: { mb: 1 },
        }}>
        {options.map((v) => (
          <FormControlLabel key={v.value} value={v.value} control={<Radio />} label={v.label} />
        ))}
      </RadioGroup>
    </FormControl>
  );
});
