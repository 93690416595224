import { useGlobals } from '@/lib/store/hooks';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';

export default function MediaRelease() {
  const { mediaRelease } = useGlobals();

  const content = mediaRelease?.content;

  return content ? (
    <Grid>
      <Grid.Col offsetMd={2} md={8}>
        <Txt variant="body" html>
          {content}
        </Txt>
      </Grid.Col>
    </Grid>
  ) : null;
}
