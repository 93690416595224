import SimpleGrid from '@/components/SimpleGrid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { EntryCardBlock } from '../EntryCardBlock';
import ExternalCardBlock from '../ExternalCardBlock';

export const EntryCardsBlock = createBlock<'blocks_entryCards_BlockType'>(
  ({ _blockMeta: meta, anchor: customAnchor, children = [] }) => {
    return (
      <BlockContainer {...{ meta, customAnchor, marginY: true, disableTransition: true }}>
        <SimpleGrid md={4}>
          {children.map((item) =>
            item.__typename === 'blocks_entryCard_BlockType' ? (
              <EntryCardBlock key={item.id} {...item} />
            ) : (
              <ExternalCardBlock key={item.id} {...item} />
            )
          )}
        </SimpleGrid>
      </BlockContainer>
    );
  }
);

export default EntryCardsBlock;
