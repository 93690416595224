import BlockHeader from '@/components/BlockHeader';
import Formie from '@/components/Formie';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../../BlockContainer';

export const FormBlock = createBlock<'blocks_form_BlockType'>(
  ({ heading, content, form, _blockMeta: meta, anchor }) => {
    return (
      <BlockContainer
        {...{
          meta,
          disableTransition: true,
          marginY: true,
        }}>
        <Grid rowGutter={false} cx={{ rowGap: 'xl' }}>
          <Grid.Col offsetMd={2} md={8}>
            <BlockHeader
              customAnchor={anchor}
              heading={heading}
              content={content}
              maxWidth="none"
              HeadingProps={{ as: 'span', variant: 'h4', autoMargin: false }}
              rowGap="sm"
            />
          </Grid.Col>
          <Grid.Col offsetMd={2} md={8}>
            {!!form && <Formie form={form} />}
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default FormBlock;
