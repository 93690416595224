'use client';

import { useView } from '@/lib/store/hooks';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import Icon from '../Icon';
import Box from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import Grid, { GridColProps } from '../ui/Grid';
import Txt, { TxtProps } from '../ui/Txt';
import * as styles from './BlockLink.css';

export type BlockLinkProps = BtnProps<
  'div',
  {
    anchor?: string;
    disableGrid?: boolean;
    GridColProps?: GridColProps;
    TxtProps?: TxtProps;
  }
>;

const BlockLink = ({
  anchor: anchorId,
  TxtProps,
  GridColProps,
  disableGrid = false,
  ...props
}: BlockLinkProps) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const t = useTranslations('social');
  const open = Boolean(anchor);

  const view = useView();
  const url = view?.url ?? '';
  const formattedUri = url.replace(/\/$/, '');

  const href = `${formattedUri}#${anchorId}`;

  const TIMEOUT = 1500;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
    navigator.clipboard.writeText(href);

    setTimeout(() => {
      setAnchor(null);
    }, TIMEOUT);
  };

  const button = (
    <Btn
      aria-label="Copy link to clipboard"
      onClick={handleClick}
      variant="unstyled"
      underline="none"
      {...mergePropsClassName(props, styles.root)}>
      <Box className={styles.iconWrapper({ open })}>
        <Icon name={open ? 'check' : 'link'} cx={{ fontSize: 'h5' }} />
      </Box>
      <AnimatePresence>
        {open && (
          <Txt
            as={motion.div}
            key="copied-text"
            initial={{ x: -15, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            variant="small"
            {...TxtProps}>
            {t('copied')}
          </Txt>
        )}
      </AnimatePresence>
    </Btn>
  );

  if (disableGrid) return button;

  return anchorId && <Grid.Col {...GridColProps}>{button}</Grid.Col>;
};
export default BlockLink;
