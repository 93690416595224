'use client';

import { FieldErrors } from '@/components/Formie/Fields/FieldErrors';
import { createField } from '@/lib/parsers/form/createFieldControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DateValidationError,
  PickerChangeHandlerContext,
  PickerValidDate,
} from '@mui/x-date-pickers/models';
import dayjs from 'dayjs';
import { useController } from 'react-hook-form';

const Field_Date = createField<'Field_Date'>(({ field, methods }) => {
  // const { handle, fields, instructions, label, labelPosition } = field;
  const { defaultDate, handle, label, placeholder, required, instructions } = field;

  // const includeTime = field.fields?.some((field) => field?.handle === 'time');

  const {
    field: fieldControl,
    fieldState: { error },
  } = useController({
    name: handle,
    control: methods.control,
    // defaultValue: null,
    defaultValue: defaultDate ?? null,
  });

  const fieldProps: DatePickerProps<PickerValidDate> = {
    label,
    value: dayjs(fieldControl.value as string | null),
    slotProps: {
      textField: {
        required,
        helperText: instructions,
        placeholder,
        // ...fieldControl,
        error: !!error,
      },
    },
  };

  const handleChange = (
    value: dayjs.Dayjs | null,
    _context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    fieldControl.onChange(value?.format('YYYY-MM-DD') ?? null);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker {...fieldProps} onChange={handleChange} />
      </LocalizationProvider>
      <FieldErrors errors={error} />
    </>
  );
});

export default Field_Date;
