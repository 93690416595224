import ButtonRow from '@/components/ButtonRow';
import CardGrid, { CardGridProps, CardItem } from '@/components/CardGrid';
import CtaButton from '@/components/CtaButton';
import { ListCard } from '@/components/EntryCard/EntryCard.List';
import SkeletonCards from '@/components/SkeletonCards';
import Grid from '@/components/ui/Grid';
import { useLatestArticleByCategory } from '@/lib/getters/useLatestEntries';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { useContentListBlock } from './useContentListBlock';
import { deleteUndefined } from '@liquorice/allsorts-craftcms-nextjs';

const ContentListBlock = createBlock<'blocks_contentList_BlockType'>(
  ({ _blockMeta: meta, anchor: customAnchor, ...props }) => {
    const items = useContentListBlock(props);

    const queryVariables = { section: 'article', orderBy: 'postDate desc' };

    const video = useLatestArticleByCategory(queryVariables, 'video');
    const insight = useLatestArticleByCategory(queryVariables, 'insight');
    const podcast = useLatestArticleByCategory(queryVariables, 'podcast');
    const event = useLatestArticleByCategory(queryVariables, 'event');

    const articles = [
      deleteUndefined(insight?.entry),
      deleteUndefined(video?.entry),
      deleteUndefined(podcast?.entry),
      deleteUndefined(event?.entry),
    ].filter((entry) => entry !== undefined && entry !== null);

    const loading = video?.loading || insight?.loading || podcast?.loading || event?.loading;

    const cardGridProps = {
      Component: ListCard,
      rowGutter: false,
      cx: { rowGap: 'xs' },
      CardProps: { elevation: false, colorSet: 'white', paper: true },
    } as CardGridProps<CardItem>;

    return (
      <BlockContainer
        {...{
          meta,
          disableTransition: true,
          marginY: true,
          customAnchor,
          BlockLinkProps: { GridColProps: { offsetMd: 2, md: 8 } },
        }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            {items ? (
              <CardGrid {...cardGridProps} items={items} />
            ) : loading ? (
              <SkeletonCards
                amount={4}
                SkeletonItems={{
                  items: [
                    { variant: 'rounded', height: 20, width: '75%' },
                    { variant: 'rounded', height: 50, width: '100%' },
                    { variant: 'rounded', height: 20, width: '75%' },
                  ],
                  cx: { rowGap: 'sm' },
                }}
              />
            ) : (
              articles && <CardGrid {...cardGridProps} items={articles} />
            )}
            {loading ? (
              <SkeletonCards
                amount={4}
                xs={3}
                cx={{ mT: 'xl' }}
                SkeletonItems={{
                  items: [{ variant: 'rounded', height: 20, width: '100%' }],
                }}
              />
            ) : (
              <ButtonRow items={articles} Component={CtaButton} />
            )}
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default ContentListBlock;
