import Flex, { FlexProps } from '@/components/ui/Flex';
import Txt, { TxtProps } from '@/components/ui/Txt';
import { BlockMeta } from '@/lib/parsers/blocks';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import BlockLink from '../Blocks/BlockLink';
import { useBlock } from '../Blocks/useBlock';
import * as style from './BlockHeader.css';

export type BlockHeaderProps = FlexProps<
  'div',
  {
    children?: React.ReactNode;
    className?: string;
    heading?: React.ReactNode;
    HeadingProps?: TxtProps;
    content?: React.ReactNode;
    ContentProps?: TxtProps;
    meta?: BlockMeta;
    customAnchor?: string | null;
  } & style.BlockHeaderVariants
>;

const BlockHeader = ({
  children,
  heading,
  HeadingProps,
  content,
  ContentProps,
  maxWidth,
  meta: _meta,
  customAnchor,
  ...props
}: BlockHeaderProps) => {
  const { anchor } = useBlock();

  const anchorId = customAnchor || anchor;

  return (
    (heading || content) && (
      <Flex
        id={anchorId}
        alignItems="start"
        {...mergePropsClassName(props, style.root({ maxWidth }))}>
        {heading && (
          <Txt variant="h2" as="h2" autoMargin {...HeadingProps}>
            {heading}
            <BlockLink {...{ anchor: anchorId, disableGrid: true }} />
          </Txt>
        )}
        {content && (
          <Txt as="div" variant="body" {...ContentProps} html>
            {content}
          </Txt>
        )}
        {children}
      </Flex>
    )
  );
};

export default BlockHeader;
