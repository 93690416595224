import MediaRelease from '@/components/MediaRelease';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../../BlockContainer';

const MediaReleaseStatementBlock = createBlock<'blocks_mediaReleaseStatement_BlockType'>(
  ({ _blockMeta: meta }) => {
    return (
      <BlockContainer meta={meta} disableTransition>
        <MediaRelease />
      </BlockContainer>
    );
  }
);

export default MediaReleaseStatementBlock;
