import Box, { BoxProps } from '@/components/ui/Box';
import Container, { ContainerProps } from '@/components/ui/Container';
import { BlockMeta } from '@/lib/parsers/blocks';
import { isEntry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { useForwardedRef } from '@/lib/utils/useForwardedRef';
import { Sprinkles } from '@/theme/sprinkles.css';
import { mergePropsClassName, slugify } from '@liquorice/allsorts-craftcms-nextjs';
import classNames from 'classnames';
import { motion, MotionProps, useInView } from 'framer-motion';
import React from 'react';
import * as style from './BlockContainer.css';
import BlockLink, { BlockLinkProps } from './BlockLink';
import { useBlock } from './useBlock';

export type BlockContainerProps = BoxProps<
  typeof motion.section,
  style.BlockContainerVariants & {
    children?: React.ReactNode;
    disableContainer?: boolean;
    disableTransition?: boolean;
    ContainerProps?: ContainerProps;
    BlockLinkProps?: BlockLinkProps;
    maxWidth?: ContainerProps['maxWidth'];
    onInView?: (inView: boolean) => void;
    meta?: BlockMeta;
    customAnchor?: string | null;
  } & {
    paddingY?: Sprinkles['paddingY'] | true;
    marginY?: Sprinkles['marginY'] | true;
  }
>;

export const BlockContainer = React.forwardRef(function BlockContainer(
  {
    children,
    className: classNameProvided,
    disableContainer,
    disableTransition,
    onInView,
    style: customStyleProp,
    marginY,
    paddingY,
    cx,
    maxWidth,
    ContainerProps,
    meta,
    customAnchor,
    BlockLinkProps,
    ...props
  }: BlockContainerProps,
  forwardedRef?: React.ForwardedRef<HTMLElement>
) {
  // const blocksContext = useBlocksContext();
  const ref = useForwardedRef(forwardedRef);
  const view = useView();
  const { anchor } = useBlock();
  const anchorId = customAnchor ? slugify(customAnchor) : anchor;

  const isInView = useInView(ref, {
    once: true,
    margin: '0px 0px -100px 0px',
  });

  const isLast = !!meta?.last;
  const isFirst = !!meta?.first;

  const isArticle = isEntry(view, 'article');
  const isOverview = isEntry(view, 'pageOverview');
  const isHome = isEntry(view, 'home');

  React.useEffect(() => {
    onInView?.(isInView);
  }, [isInView, onInView]);

  const styleProp: MotionProps['style'] = {
    ...(!disableTransition && {
      transition: 'opacity 0.4s ease-in',
      opacity: isInView ? 1 : 0,
    }),
    ...customStyleProp,
  };

  /**
   * If disableContainer is true, we don't render a container
   * If disableContainer is false, we render a container with the maxWidth provided
   */
  let inner = children;

  if (!disableContainer)
    inner = (
      <Container
        {...{
          maxWidth,
          ...mergePropsClassName(ContainerProps, style.container),
        }}>
        {customAnchor && anchorId ? <BlockLink anchor={anchorId} {...BlockLinkProps} /> : null}
        {children}
      </Container>
    );

  const className = classNames(
    classNameProvided,
    style.root({
      last: isLast,
      first: isFirst,
    })
  );

  const blockSpace = isOverview || isHome ? '6xl' : '5xl';

  let blockTopMargin = marginY === true ? blockSpace : marginY;
  let blockBottomMargin = marginY === true ? blockSpace : marginY;
  const blockPadding = paddingY === true ? blockSpace : paddingY;

  if (isFirst && isArticle) blockTopMargin = 'lg';
  if (isFirst && isHome) blockTopMargin = 'none';
  if (paddingY === true && (isFirst || isLast))
    (blockBottomMargin = 'none'), (blockTopMargin = 'none');

  return (
    <Box
      as={motion.section}
      className={className}
      data-block={meta?.typename.split('_')[1]}
      id={anchorId}
      ref={ref}
      {...props}
      style={styleProp}
      cx={{
        mT: blockTopMargin,
        mB: blockBottomMargin,
        pY: blockPadding,
        ...cx,
      }}>
      {inner}
    </Box>
  );
});
