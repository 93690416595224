import { CardProps } from '@/components/Card';
import { createBlock } from '@/lib/parsers/blocks';
import { Entry, EntryTypeId } from '@/lib/parsers/entries';

/** Hook to apply card "overrides" and extra features */
export const useContentListBlock = createBlock<
  'blocks_contentList_BlockType',
  NoProps,
  Entry<'article'>[] | null
>((props) => {
  const { articleMultiple: items } = props;

  // Bail early
  if (!items?.length) return null;

  return items as Entry<'article'>[];
});

export type EntryCardProps<T extends EntryTypeId = EntryTypeId> = CardProps<Entry<T>>;
