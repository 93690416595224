import { FieldErrors } from '@/components/Formie/Fields/FieldErrors';
import { SanitisedField } from '@/lib/parsers/form';
import { createField } from '@/lib/parsers/form/createFieldControl';
import { useController } from 'react-hook-form';
import { DefaultSelectField } from './DefaultSelectField';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DropDownFields = Extract<SanitisedField, { options: any[] }>['__typename'];

export const Field_Dropdown = createField<DropDownFields>(({ field, methods }) => {
  const {
    options,
    defaultValue: maybeDefaultValue,
    handle,
    label,
    placeholder,
    required,
    instructions,
  } = field;

  const multi = field.__typename === 'Field_Dropdown' ? field.multi : false;

  const { control } = methods;
  const defaultValue = multi ? [] : maybeDefaultValue ?? '';

  const {
    field: { ref, ...controlledField },
    fieldState: { error },
  } = useController({
    name: handle,
    control,
    defaultValue,
  });

  return (
    <>
      <DefaultSelectField
        {...{
          ...controlledField,
          SelectProps: {
            ref,
            multiple: multi,
          },
          error: !!error,
          helperText: instructions,
          label,
          placeholder,
          required,
          options,
        }}
      />
      <FieldErrors errors={error} />
    </>
  );
});
