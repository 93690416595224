import Box from '@/components/ui/Box';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';

const QuoteBlock = createBlock<'blocks_quote_BlockType'>(
  ({ quote, author, role, _blockMeta: meta, anchor: customAnchor }) => {
    return (
      <BlockContainer
        {...{
          meta,
          BlockLinkProps: { GridColProps: { offsetMd: 2, md: 8 } },
          disableTransition: true,
          marginY: true,
          customAnchor,
        }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <Box colorSet="primary" paper cx={{ p: 'xl' }}>
              <Txt variant="h5" as="h5" html cx={{ mB: 'lg' }}>
                {quote}
              </Txt>
              <Box cx={{ width: { md: '50' } }}>
                <Txt variant="large" as="p" autoMargin>
                  {author}
                </Txt>
                <Txt variant="body" as="p">
                  {role}
                </Txt>
              </Box>
            </Box>
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default QuoteBlock;
