import { FieldComponentProps, FieldTypename } from './formieParser';

/**
 * Create a {@link Field} consumer React Component by providing
 * the `__typename` of `FieldType` as T
 */

export const createField = <T extends FieldTypename = FieldTypename, P = NoProps>(
  FieldComponent: (props: FieldComponentProps<T, P>) => JSX.Element
) => {
  const FieldControl = (props: FieldComponentProps<T, P>) => {
    return <FieldComponent {...props} />;
  };

  return FieldControl;
};
