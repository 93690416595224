import BlockHeader from '@/components/BlockHeader';
import { ProfileCard } from '@/components/EntryCard';
import SimpleGrid from '@/components/SimpleGrid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { useProfileCardsBlock } from './useProfileCardsBlock';

const ProfileCardsBlock = createBlock<'blocks_profileCards_BlockType'>(
  ({ heading, _blockMeta: meta, anchor, ...props }) => {
    const items = useProfileCardsBlock(props) ?? [];

    return (
      <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
        <BlockHeader customAnchor={anchor} heading={heading} HeadingProps={{ cx: { mB: 'lg' } }} />
        <SimpleGrid md={6} lg={4} rowGutter={false} cx={{ rowGap: { xs: 'xl', lg: '3xl' } }}>
          {items.map((item, index) => (
            <ProfileCard
              key={index}
              item={item}
              disableLinkWrap={true}
              PhoneProps={{ enableLinkWrap: true }}
              EmailProps={{ enableLinkWrap: true }}
              TitleProps={{ enableLinkWrap: true }}
            />
          ))}
        </SimpleGrid>
      </BlockContainer>
    );
  }
);

export default ProfileCardsBlock;
