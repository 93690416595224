import { FieldErrors } from '@/components/Formie/Fields/FieldErrors';
import Txt from '@/components/ui/Txt';
import { createField } from '@/lib/parsers/form/createFieldControl';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel } from '@mui/material';
import { useController } from 'react-hook-form';

export const Field_Agree = createField<'Field_Agree'>(({ field, methods }) => {
  const {
    label,
    handle,
    instructions,
    descriptionHtml,
    required,
    checkedValue,
    // uncheckedValue,
    labelPosition,
    defaultValue = null,
  } = field;

  const uncheckedValue = null;

  const { control } = methods;

  const {
    field: fieldControl,
    fieldState: { error },
  } = useController({
    name: handle,
    control,
    defaultValue,
  });

  return (
    <FormControl required={required} error={!!error}>
      <FormLabel component="legend" sx={{ mb: 1 }} hidden={labelPosition === 'hidden'}>
        {label}
      </FormLabel>
      <FormControlLabel
        {...{
          componentsProps: {
            typography: { color: 'inherit' },
          },
          required,
          label: (
            <Txt html as="span">
              {descriptionHtml}
            </Txt>
          ),
          control: (
            <Checkbox
              onChange={(_e, checked) =>
                fieldControl.onChange(checked ? checkedValue : uncheckedValue)
              }
              checked={fieldControl.value === checkedValue}
              value={checkedValue}
            />
          ),
        }}
      />
      <FormHelperText>{instructions}</FormHelperText>
      <FieldErrors errors={error} />
    </FormControl>
  );
});
