import { FieldErrors } from '@/components/Formie/Fields/FieldErrors';
import { SanitisedField } from '@/lib/parsers/form';
import { createField } from '@/lib/parsers/form/createFieldControl';
import { Controller } from 'react-hook-form';
import { DefaultTextField, DefaultTextFieldProps } from './DefaultTextField';

type TextFields = Extract<SanitisedField, { handle: string }>['__typename'];

export const Field_Text = createField<TextFields>(({ field, methods }) => {
  const { defaultValue, handle, label, placeholder, required, instructions } = field;

  const fieldProps: DefaultTextFieldProps = {
    // error: !!error,
    helperText: instructions,
    label,
    placeholder,
    required,
  };

  switch (field.__typename) {
    case 'Field_MultiLineText':
      fieldProps.multiline = true;
      fieldProps.minRows = 3;
      break;

    case 'Field_Email':
      fieldProps.type = 'email';
      break;

    case 'Field_Number':
      fieldProps.type = 'number';
      break;

    case 'Field_Phone':
      fieldProps.type = 'tel';
      break;
  }

  return (
    <Controller
      name={handle}
      control={methods.control}
      defaultValue={defaultValue ?? ''}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <>
          <DefaultTextField {...rest} error={!!error} {...fieldProps} inputProps={{ ref }} />
          <FieldErrors errors={error} />
        </>
      )}
    />
  );
});
