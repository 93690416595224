import { createBlock } from '@/lib/parsers/blocks';
import { Entry } from '@/lib/parsers/entries';

/** Hook to apply card "overrides" and extra features */
export const useProfileCard = createBlock<
  'blocks_presenterCard_BlockType',
  NoProps,
  Entry<'profile'> | null
>((props) => {
  const {
    profileSingle: item,
    heading: maybeHeading,
    content: maybeContent,
    imageSingle: maybeImage,
  } = props;

  // Bail early
  if (!item) return null;

  if (maybeHeading) item.title = maybeHeading;
  if (maybeContent) item.summary = maybeContent;
  if (maybeImage) item.entryImage = maybeImage;

  return item as Entry<'profile'>;
});
