'use client';

// import Swiper react and required modules
import { A11y, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles

import 'swiper/css';
import 'swiper/css/pagination';
import { useSwiper } from 'swiper/react';
import * as style from './Carousel.css';

import { useState } from 'react';
import Icon from '../Icon';
import Box from '../ui/Box';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';

const CarouselNavigation = ({
  slides,
  activeIndex,
}: {
  slides: unknown[];
  activeIndex: number;
}) => {
  const swiper = useSwiper();

  const handleNext = () => {
    swiper.slideNext();
  };
  const handlePrev = () => {
    swiper.slidePrev();
  };

  // A bit hacky to get type safe access to the caption prop
  const activeCaption = slides[activeIndex] as { props: { caption: string } };

  return (
    <Flex
      justifyContent="between"
      cx={{ direction: { xs: 'column-reverse', md: 'row' } }}
      className={style.navigation}>
      <Txt as="figcaption" color="neutralDark" cx={{ width: { xs: '100', md: '75' } }}>
        {activeCaption.props.caption}
      </Txt>
      <Flex direction="row" columnGap="lg">
        <Txt color="neutralDark">{activeIndex + 1 + '/' + slides.length}</Txt>
        <Flex direction="row" columnGap="sm">
          <Icon className={style.navigationPrev} name="arrowLeft" onClick={handlePrev} />
          <Icon className={style.navigationNext} name="arrowRight" onClick={handleNext} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const CarouselPagination = ({
  slides,
  activeIndex,
}: {
  slides: unknown[];
  activeIndex: number;
}) => {
  return (
    <Flex className={style.pagination} direction="row" justifyContent="center" spacing="3xs">
      {slides.map((_, i) => (
        <Box
          key={i}
          role="button"
          aria-label={`slide ${i + 1}`}
          className={style.paginationBullet({
            active: i === activeIndex,
          })}
        />
      ))}
    </Flex>
  );
};

export type CarouselProps = {
  items: JSX.Element[];
  hideNavigation?: boolean;
  // disablePagination?: boolean
};

const Carousel = (props: CarouselProps) => {
  const { items = [], hideNavigation } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  if (!items.length) return <></>;
  return (
    <Swiper
      modules={[Pagination, A11y]}
      slidesPerView={1}
      className=""
      onSlideChange={(s) => setActiveIndex(s.activeIndex)}>
      {items.map((item, i) => (
        <SwiperSlide key={i}>
          <Box>{item}</Box>
        </SwiperSlide>
      ))}
      {!hideNavigation && <CarouselNavigation activeIndex={activeIndex} slides={items} />}
    </Swiper>
  );
};

export default Carousel;
