import { fmtCategoryNames } from '@/lib/utils/format';
import Card from '../Card';
import { EntryCardProps } from './EntryCardProps';

export type ArticleCardProps = EntryCardProps<'article'>;

export const ArticleCard = ({ item, ...props }: ArticleCardProps) => {
  const {
    uri: href,
    title,
    pageTitle,
    summary: description,
    postDate,
    contentTypeCategorySingle,
  } = item;

  const contentTypeName = fmtCategoryNames(contentTypeCategorySingle);

  return (
    <Card
      colorSet="primaryLight"
      paper
      fullHeight
      elevation={false}
      {...props}
      item={{
        href,
        title: pageTitle ?? title,
        meta: [contentTypeName, postDate],
        description,
      }}>
      <Card.Body cx={{ mX: 'xs', mT: 'md' }}>
        <Card.Meta />
        <Card.Title variant="h5" cx={{ mY: 'lg' }} />
      </Card.Body>
      <Card.Foot cx={{ mX: 'xs', mB: 'xl' }}>
        <Card.Cta
          variant="text"
          size="small"
          EndIconProps={{ name: 'arrowRight', cx: { color: 'accent' } }}
          cx={{ fontSize: 'medium' }}
        />
      </Card.Foot>
    </Card>
  );
};
