import FormieFieldRows from '@/components/Formie/FormieFieldRows';
import { createField } from '@/lib/parsers/form/createFieldControl';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { Field_Text } from './Field_Text';

const Field_NameSingle = createField<'Field_Name'>((props) => {
  return <Field_Text {...props} />;
});

const Field_NameMulti = createField<'Field_Name'>(({ field, methods }) => {
  const { handle, nestedRows, instructions, label, labelPosition } = field;

  return (
    <FormControl fullWidth>
      <FormLabel component="legend" sx={{ mb: 2 }} hidden={labelPosition === 'hidden'}>
        {label}
      </FormLabel>
      {instructions && <FormHelperText>{instructions}</FormHelperText>}
      <FormieFieldRows rows={nestedRows} parentHandle={handle} methods={methods} />
    </FormControl>
  );
});

export const Field_Name = createField<'Field_Name'>((props) => {
  const { field } = props;
  const { useMultipleFields } = field;
  return useMultipleFields ? <Field_NameMulti {...props} /> : <Field_NameSingle {...props} />;
});
