import { ToolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import Box from '../ui/Box';
import * as styles from './Document.css';

import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { useNextHydrated } from '@/lib/utils/createToggleStore';
import { Icon, MinimalButton, Position, ScrollMode, Tooltip } from '@react-pdf-viewer/core';
import { RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';

type Toolbar = React.PropsWithChildren<{
  toolbar: ToolbarPlugin;
}>;

const Toolbar = ({ toolbar }: Toolbar) => {
  const { Toolbar } = toolbar;
  const nextHydrated = useNextHydrated();

  return (
    nextHydrated && (
      <Box className={styles.toolbar}>
        <Toolbar>
          {(props: ToolbarSlot) => {
            const {
              CurrentPageLabel,
              Download,
              EnterFullScreen,
              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              ZoomIn,
              ZoomOut,
              SwitchScrollMode,
              // Print,
            } = props;
            return (
              <>
                <ZoomOut />
                <ZoomIn />
                <GoToPreviousPage>
                  {(props: RenderGoToPageProps) => (
                    <Tooltip
                      position={Position.BottomCenter}
                      target={
                        <MinimalButton onClick={props.onClick}>
                          <Icon size={16}>
                            <path d="M18.4.5,5.825,11.626a.5.5,0,0,0,0,.748L18.4,23.5" />
                          </Icon>
                        </MinimalButton>
                      }
                      content={() => 'Previous page'}
                      offset={{ left: 0, top: 8 }}
                    />
                  )}
                </GoToPreviousPage>
                <CurrentPageLabel />
                /<NumberOfPages />
                <GoToNextPage>
                  {(props: RenderGoToPageProps) => (
                    <Tooltip
                      position={Position.BottomCenter}
                      target={
                        <MinimalButton onClick={props.onClick}>
                          <Icon size={16}>
                            <path d="M5.651,23.5,18.227,12.374a.5.5,0,0,0,0-.748L5.651.5" />
                          </Icon>
                        </MinimalButton>
                      }
                      content={() => 'Next page'}
                      offset={{ left: 0, top: 8 }}
                    />
                  )}
                </GoToNextPage>
                <EnterFullScreen />
                <Download />
                <SwitchScrollMode mode={ScrollMode.Horizontal} />
                <SwitchScrollMode mode={ScrollMode.Vertical} />
                {/* <Print /> */}
              </>
            );
          }}
        </Toolbar>
      </Box>
    )
  );
};

export default Toolbar;
