import { isEntry } from '@/lib/parsers/entries';
import Card from '../Card';
import Box from '../ui/Box';
import { ArticleCard } from './EntryCard.Article';
import { ProfileCard } from './EntryCard.Profile';
import { EntryCardProps } from './EntryCardProps';

export const EntryCardDefault = (props: EntryCardProps) => {
  if (!props.item) return null;

  if (isEntry(props.item, 'article'))
    return <ArticleCard {...(props as EntryCardProps<'article'>)} />;
  if (isEntry(props.item, 'profile'))
    return <ProfileCard {...(props as EntryCardProps<'profile'>)} />;

  const { uri: href, title, summary: description, entryImage: image } = props.item;

  return (
    <Card
      {...props}
      fullHeight
      elevation={false}
      padding="sm"
      item={{
        title,
        description,
        image,
        href,
      }}>
      <Box cx={{ pX: 'sm', pT: 'sm' }}>
        <Card.Image ratio="landscape" transform="fullFit" />
      </Box>
      <Card.Body>
        <Card.Title variant="h4" cx={{ mB: 'none' }} />
        <Card.Description />
      </Card.Body>
      <Card.Foot cx={{ mB: 'sm' }}>
        <Card.Cta
          variant="text"
          size="normal"
          EndIconProps={{ name: 'arrowRight', cx: { color: 'accent' } }}
          cx={{ fontSize: 'medium' }}
        />
      </Card.Foot>
    </Card>
  );
};

export default EntryCardDefault;
