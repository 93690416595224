import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import ImageWithCaption from '@/components/ImageWithCaption';
import Grid from '@/components/ui/Grid';

const ImageBlock = createBlock<'blocks_image_BlockType'>(({ imageSingle, caption, _blockMeta }) => {
  const width = imageSingle?.width ?? 0;
  const height = imageSingle?.height ?? 0;
  const isPortrait = height > width;

  return (
    <BlockContainer meta={_blockMeta} marginY disableTransition>
      <Grid>
        <Grid.Col
          {...{
            offsetMd: isPortrait ? 4 : 2,
            md: isPortrait ? 4 : 8,
          }}>
          <ImageWithCaption
            imageProps={{
              ...imageSingle,
              rounded: false,
              // ratio: 'landscape',
              fixedRatio: true,
            }}
            caption={caption}
          />
        </Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default ImageBlock;
