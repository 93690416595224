import FormieField from '@/components/Formie/FormieField';
import { Field, FormMethods } from '@/lib/parsers/form';
import { Stack } from '@mui/material';

type FormieFieldRowsProps = {
  rows?: {
    id: string;
    rowFields: Field[];
  }[];
  parentHandle?: string;
  methods: FormMethods;
};

const FormieFieldRows = ({ rows = [], parentHandle, methods }: FormieFieldRowsProps) => {
  return (
    <Stack rowGap={3}>
      {rows.map((row, i) => (
        <Stack
          key={i}
          direction="row"
          columnGap={2}
          rowGap={2}
          flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
          {row.rowFields.map((field, j) => (
            <FormieField
              key={field.id ?? `${i}-${j}`}
              methods={methods}
              field={{
                ...field,
                handle: parentHandle ? `${parentHandle}.${field.handle}` : field.handle,
              }}
            />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default FormieFieldRows;
