import { Field_Address } from '@/components/Formie/Fields/Field_Address';
import { Field_Agree } from '@/components/Formie/Fields/Field_Agree';
import { Field_Checkboxes } from '@/components/Formie/Fields/Field_Checkboxes';
import Field_Date from '@/components/Formie/Fields/Field_Date';
import { Field_Dropdown } from '@/components/Formie/Fields/Field_Dropdown';
import { Field_FileUpload } from '@/components/Formie/Fields/Field_FileUpload';
import { Field_Name } from '@/components/Formie/Fields/Field_Name';
import { Field_Radio } from '@/components/Formie/Fields/Field_Radio';
import { Field_Text } from '@/components/Formie/Fields/Field_Text';
import Divider from '@/components/ui/Divider';
import Txt from '@/components/ui/Txt';
import { Field, FormMethods } from '@/lib/parsers/form';
import { Box, BoxProps, SxProps } from '@mui/material';
import useFieldConditions from './useFieldConditions';

export type FormieFieldProps = {
  field: Field;
  methods: FormMethods;
};

export default function FormieField({ field, methods }: FormieFieldProps) {
  // Calculate any conditions
  const conditionalShow = useFieldConditions(field.conditions, field.enableConditions, methods);

  if (!conditionalShow) return null;

  let inner: JSX.Element = <>{field.__typename}</>;

  const boxProps: BoxProps = {};

  const boxStyle: SxProps = {
    flexGrow: 1,
  };

  switch (field.__typename) {
    case 'Field_Heading':
      inner = <Txt variant="h4">{field.label}</Txt>;
      break;

    case 'Field_Section':
      inner = <Divider />;
      break;

    case 'Field_Address':
      inner = <Field_Address field={field} methods={methods} />;
      break;
    case 'Field_Name':
      inner = <Field_Name field={field} methods={methods} />;
      break;
    case 'Field_Agree':
      inner = <Field_Agree field={field} methods={methods} />;
      break;
    case 'Field_NamePrefix':
      boxStyle.width = 100;
      boxStyle.flexGrow = 0;
      inner = <Field_Dropdown field={field} methods={methods} />;
      break;
    case 'Field_AddressCountry':
      inner = <Field_Dropdown field={field} methods={methods} />;
      break;
    case 'Field_Date':
      inner = <Field_Date field={field} methods={methods} />;
      break;
    case 'Field_Dropdown':
      inner = <Field_Dropdown field={field} methods={methods} />;
      break;
    case 'Field_Checkboxes':
      inner = <Field_Checkboxes field={field} methods={methods} />;
      break;
    case 'Field_Radio':
      inner = <Field_Radio field={field} methods={methods} />;
      break;
    case 'Field_FileUpload':
      inner = <Field_FileUpload field={field} methods={methods} />;
      break;
    default:
      inner = <Field_Text field={field} methods={methods} />;
  }

  return (
    <Box {...boxProps} sx={boxStyle}>
      {inner}
    </Box>
  );
}
