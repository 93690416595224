import Card from '@/components/Card';
import { createBlock } from '@/lib/parsers/blocks';

export const TextCardBlock = createBlock<'blocks_textCard_BlockType'>(
  ({ heading, content, ...props }) => {
    return (
      <Card
        padding="lg"
        elevation={false}
        fullHeight
        {...props}
        item={{ title: heading, description: content }}>
        <Card.Body cx={{ pX: 'xs', pT: 'md', rowGap: 'sm' }}>
          <Card.Title variant="h5" />
          <Card.Description />
        </Card.Body>
      </Card>
    );
  }
);

export default TextCardBlock;
