import { EntryCardDefault } from '@/components/EntryCard';
import { useEntryCard } from '@/components/EntryCard/useEntryCard';
import { createBlock } from '@/lib/parsers/blocks';

export const EntryCardBlock = createBlock<'blocks_entryCard_BlockType'>(({ ...props }) => {
  const item = useEntryCard(props);

  if (!item) return null;

  return <EntryCardDefault item={item} />;
});

export default EntryCardBlock;
