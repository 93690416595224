'use client';

import useFormieCaptchas from '@/components/Formie/FormieCaptcha';
import FormieFieldRows from '@/components/Formie/FormieFieldRows';
import { FormValues } from '@/components/Formie/formTypes';
import useFormieSubmit from '@/components/Formie/useFormieSubmit';
import { ParsedForm } from '@/lib/parsers/form';
import React from 'react';
import { useForm } from 'react-hook-form';
import Flex from '../ui/Flex';
import { FormieSubmitRow } from './FormieSubmitRow';
export type FormieProps = React.PropsWithChildren<{
  form: ParsedForm;
}>;

const Formie = ({ form }: FormieProps) => {
  const methods = useForm<FormValues>({
    mode: 'onChange',
  });

  const { triggerSubmit, isSuccess, isError, isLoading, errors } = useFormieSubmit();

  const { getCaptchaValues } = useFormieCaptchas({
    captchas: form.captchas,
  });

  const onSubmit = async (values: FormValues) => {
    const mutationName = form.submissionMutationName;
    const captchaValues = await getCaptchaValues();
    triggerSubmit(mutationName, { ...values, ...captchaValues }, form.uid);
  };

  // -----------------------------------------------------------------------------------
  // ---- Errors ----

  React.useEffect(() => {
    methods.clearErrors();
    Object.entries(errors ?? {}).forEach(([key, value]) => {
      methods.setError(key, {
        type: 'manual',
        message: value.join(', '),
      });
    });
  }, [errors, methods]);

  // -----------------------------------------------------------------------------------

  return (
    <Flex as="form" rowGap="md" noValidate onSubmit={methods.handleSubmit(onSubmit)}>
      <FormieFieldRows rows={form?.rows} methods={methods} />
      <FormieSubmitRow
        {...{
          isSuccess,
          isError: isError && !methods.formState.isValid,
          isLoading: isLoading || methods.formState.isSubmitting,
          messages: {
            error: form.settings?.errorMessageHtml ?? 'An error occurred',
            success: form.settings?.submitActionMessageHtml ?? 'Submitted',
          },
        }}
      />
    </Flex>
  );
};

export default Formie;
