import Icon, { IconName } from '../Icon';
import Box, { BoxProps } from '../ui/Box';

export type UnstyledAccordionItemCaretProps<C extends React.ElementType = 'div'> = BoxProps<
  C,
  {
    icon?: IconName;
    openIcon?: IconName;
    isActive?: boolean;
    openIconProps?: BoxProps;
  }
>;

const UnstyledAccordionItemCaret = ({
  openIcon = 'minus',
  icon = 'plus',
  isActive,
  openIconProps,
  ...props
}: UnstyledAccordionItemCaretProps) => {
  return (
    <Box {...(isActive ? openIconProps : props)}>
      <Icon name={isActive ? openIcon : icon} />
    </Box>
  );
};

export default UnstyledAccordionItemCaret;
