import { FieldErrors } from '@/components/Formie/Fields/FieldErrors';
import Icon from '@/components/Icon';
import { createField } from '@/lib/parsers/form/createFieldControl';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, FormControl, FormHelperText, FormLabel, Stack, styled } from '@mui/material';
import { useTranslations } from 'next-intl';
import React from 'react';
import { useController } from 'react-hook-form';
import { FileUploadInput } from '../formTypes';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const Field_FileUpload = createField<'Field_FileUpload'>(({ field, methods }) => {
  const t = useTranslations('actions');

  const {
    // defaultValue = [],
    handle,
    label,
    // placeholder,
    // multi: multiple,
    limitFiles,
    sizeLimit,
    // sizeMinLimit,
    required,
    instructions,
    labelPosition,
    allowedKinds: accept,
  } = field;

  const maxFiles = limitFiles ?? 1;
  const multiple = maxFiles > 1;
  const maxFileSize = (sizeLimit ?? 10) * 1024 * 1024; // 10MB

  const validateFile = (file: File) => {
    if (file.size > maxFileSize) {
      return 'File is too large';
    }

    return null;
  };

  const { field: fieldControl, fieldState } = useController({
    name: handle,
    defaultValue: [],
    control: methods.control,
  });

  const setValue = fieldControl.onChange;
  const [internalValue, setInternalValue] = React.useState<FileUploadInput[] | null>(null);

  type FileErrors = [name: string, error: string][];

  const [fileErrors, setFileErrors] = React.useState<FileErrors>([]);

  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;

    const files = target.files ? Array.from(target.files) : [];
    const filesAsText: FileUploadInput[] = [];
    const newFileErrors: FileErrors = [];

    files.forEach((file, fileIndex) => {
      const fileError = validateFile(file);
      const fileName = file.name;

      if (fileError) {
        newFileErrors.push([fileName, fileError]);
        return;
      }

      if (fileIndex >= maxFiles) {
        newFileErrors.push([fileName, 'Max files exceeded']);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target?.result;
        if (fileData) {
          filesAsText[fileIndex] = { fileData: fileData.toString(), filename: file.name };
          setInternalValue(filesAsText.length ? filesAsText : null);
          setValue(filesAsText.length ? filesAsText : null);
        }
      };
      reader.readAsDataURL(file);
    });

    setFileErrors(newFileErrors);
  };

  const handleRemove = (fileIndex: number) => () => {
    const currentValue = internalValue ?? [];
    const newValue = currentValue.filter((_, i) => i !== fileIndex);
    setInternalValue(newValue.length ? newValue : null);
    setValue(newValue.length ? newValue : null);
  };

  // const maxFiles = limitFiles ? Number(limitFiles) || 1 : undefined;
  const nFiles = internalValue?.length ?? 0;

  const allowAdd = nFiles < maxFiles;
  return (
    <FormControl required={required} /* error={error} */>
      <FormLabel component="legend" sx={{ mb: 2 }} hidden={labelPosition === 'hidden'}>
        {label}
      </FormLabel>
      <FormHelperText>{instructions}</FormHelperText>
      <Button
        component="label"
        role={undefined}
        variant="outlined"
        tabIndex={-1}
        disabled={!allowAdd}
        startIcon={<CloudUploadIcon />}>
        {t('chooseFiles', { count: maxFiles })}
        <VisuallyHiddenInput
          type="file"
          {...{
            onChange: handleCapture,
            multiple,
            accept,
          }}
          style={{ display: 'none' }}
        />
      </Button>
      <Stack /* rowGap={2} */ alignItems="flex-start" sx={{ mt: 2 }}>
        {internalValue?.map((file, i) => (
          <Button
            key={i}
            variant="text"
            endIcon={<Icon name="close" />}
            sx={{ textTransform: 'none' }}
            onClick={handleRemove(i)}>
            {file.filename}
          </Button>
        ))}
      </Stack>
      {fileErrors.map(([name, error], i) => (
        <FormHelperText key={i} error>
          {name}: {error}
        </FormHelperText>
      ))}
      <FieldErrors errors={fieldState.error} />
    </FormControl>
  );
});
