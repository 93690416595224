import { FieldErrors } from '@/components/Formie/Fields/FieldErrors';
import { createField } from '@/lib/parsers/form/createFieldControl';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { useController } from 'react-hook-form';

export const Field_Checkboxes = createField<'Field_Checkboxes'>(({ field, methods }) => {
  const {
    label,
    handle,
    layout,
    required,
    options,
    multi: _multi,
    instructions,
    labelPosition,
  } = field;

  const defaultValue = options.reduce<string[]>((acc, v) => {
    if (v.isDefault) {
      acc.push(v.value);
    }
    return acc;
  }, []);

  const {
    field: fieldControl,
    fieldState: { error },
  } = useController({
    name: handle,
    control: methods.control,
    defaultValue,
  });

  return (
    <FormControl required={required} /* error={error} */>
      <FormLabel
        component="legend"
        sx={{ mb: 1 }}
        hidden={labelPosition === 'hidden'}
        error={!!error}>
        {label}
      </FormLabel>
      <FieldErrors errors={error} />
      <FormHelperText>{instructions}</FormHelperText>
      <FormGroup row={layout === 'horizontal'} sx={{ mb: 1 }}>
        {options.map((v) => {
          const fieldProps: CheckboxProps = {
            // ...fieldControl,
            // name: `${handle}[]`,
            value: v.value,
            onChange: (e, checked) => {
              const value = (fieldControl.value ?? []) as string[];
              if (checked) {
                fieldControl.onChange([...value, v.value]);
              } else {
                fieldControl.onChange(value.filter((x) => x !== v.value));
              }
            },
          };
          return (
            <FormControlLabel
              key={v.value}
              label={v.label}
              control={<Checkbox style={{ color: 'currentColor' }} {...fieldProps} />}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
});
