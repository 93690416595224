import SimpleGrid from '@/components/SimpleGrid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import EntryCardBlock from '../EntryCardBlock';
import ExternalCardBlock from '../ExternalCardBlock';

const DualCardsBlock = createBlock<'blocks_dualCards_BlockType'>(
  ({ children = [], _blockMeta: meta, anchor }) => {
    return (
      <BlockContainer {...{ meta, disableTransition: true, marginY: true, customAnchor: anchor }}>
        <SimpleGrid md={6} rowGutter={false} cx={{ rowGap: { xs: 'xl', lg: '3xl' } }}>
          {children.map((item) =>
            item.__typename === 'blocks_entryCard_BlockType' ? (
              <EntryCardBlock key={item.id} {...item} />
            ) : (
              <ExternalCardBlock key={item.id} {...item} />
            )
          )}
        </SimpleGrid>
      </BlockContainer>
    );
  }
);

export default DualCardsBlock;
