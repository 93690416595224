import SimpleGrid from '@/components/SimpleGrid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import TextCardBlock from '../TextCardBlock';

const TextCardsBlock = createBlock<'blocks_textCards_BlockType'>(
  ({ _blockMeta: meta, anchor: customAnchor, children = [] }) => {
    return (
      <BlockContainer
        {...{ meta, customAnchor, marginY: true, disableTransition: true, maxWidth: 'lg' }}>
        <SimpleGrid md={6} lg={4}>
          {children.map((item, i) => (
            <TextCardBlock key={item.id ?? i} {...item} />
          ))}
        </SimpleGrid>
      </BlockContainer>
    );
  }
);

export default TextCardsBlock;
