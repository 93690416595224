import { Entry } from '@/lib/parsers/entries';
import { useBreakpointMax } from '@/theme';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import { BtnProps } from '../ui/Btn';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import Txt, { TxtProps } from '../ui/Txt';
import * as styles from './ButtonRow.css';

type BtnItem = Entry<'article'>;

export type ButtonRowProps = {
  Component: React.ComponentType<BtnProps>;
  items: BtnItem[];
  BtnProps?: BtnProps;
  TxtProps?: TxtProps;
};

export type ButtonItemProps = Omit<ButtonRowProps, 'items'> & {
  item: BtnItem;
};

const ButtonItem = ({ item, Component, BtnProps, TxtProps }: ButtonItemProps) => {
  const componentProps = mergePropsClassName(BtnProps, styles.button);
  const category = item?.contentTypeCategorySingle;
  if (!category) return null;

  const title = 'All' + ' ' + category?.title?.toLowerCase();
  const href = category?.uri;

  return (
    <Component {...{ href, ...componentProps }}>
      <Txt as="span" variant="large" {...TxtProps}>
        {title}
      </Txt>
    </Component>
  );
};

const ButtonRow = ({ items, Component, BtnProps, TxtProps }: ButtonRowProps) => {
  const sm = useBreakpointMax('sm');

  return (
    <Flex
      columnGap="sm"
      wrap="wrap"
      rowGap="xs"
      alignItems="start"
      cx={{ mT: 'xl', direction: { xs: 'column', sm: 'row' } }}>
      {items?.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <ButtonItem item={item} Component={Component} BtnProps={BtnProps} TxtProps={TxtProps} />
            {i !== items.length - 1 && !sm && (
              <Divider className={styles.divider} direction="vertical" />
            )}
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

export default ButtonRow;
