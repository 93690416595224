import { createBlock } from '@/lib/parsers/blocks';
import React from 'react';
import { BlockContainer } from '../../BlockContainer';
import Socials from '@/components/Socials';
import Txt from '@/components/ui/Txt';
import { useTranslations } from 'next-intl';
import Flex from '@/components/ui/Flex';
import Divider from '@/components/ui/Divider';
import * as styles from './SocialLinksBlock.css';
import Grid from '@/components/ui/Grid';
import { useBreakpointMin } from '@/theme';

const SocialLinksBlock = createBlock<'blocks_socialLinks_BlockType'>(
  ({ _blockMeta: meta, anchor: customAnchor, socialLinks }) => {
    const t = useTranslations('social');
    const md = useBreakpointMin('md');

    return (
      <BlockContainer {...{ meta, customAnchor, disableTransition: true, marginY: true }}>
        <Grid rowGutter>
          <Grid.Col>
            <Flex direction="row" alignItems="center" columnGap="gutter">
              <Txt as="h4" variant="h4" cx={{ m: 'none' }} className={styles.heading}>
                {t('connectWithUs')}
              </Txt>
              {md && <Divider cx={{ width: 'full' }} />}
            </Flex>
          </Grid.Col>
          <Grid.Col>
            <Flex direction="row" columnGap="xs">
              <Socials links={socialLinks} BtnProps={{ cx: { fontSize: 'h4' } }} />
            </Flex>
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default SocialLinksBlock;
