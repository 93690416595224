// import { fmtCategoryNames } from '@/lib/utils/format';
import { ImageEntry } from '@/lib/parsers/images';
import { fmtCategoryNames, fmtRole } from '@/lib/utils/format';
import DefaultImage from 'public/images/default-image.png';
import Card from '../Card';
import { CardEmailProps } from '../Card/Card.Email';
import { CardPhoneProps } from '../Card/Card.Phone';
import { CardTitleProps } from '../Card/Card.Title';
import Divider from '../ui/Divider';
import { EntryCardProps } from './EntryCardProps';

export type ProfileCardProps = EntryCardProps<'profile'> & {
  PhoneProps?: CardPhoneProps;
  EmailProps?: CardEmailProps;
  TitleProps?: CardTitleProps;
};

export const ProfileCard = ({
  item,
  PhoneProps,
  EmailProps,
  TitleProps,
  ...props
}: ProfileCardProps) => {
  const {
    uri: href,
    firstName,
    lastName,
    honorific,
    entryImage: image,
    email,
    profileRoleSingle,
    displayRole,
    displayRoleMode,
    phoneOffice,
    phoneMobile,
  } = item;

  const { src, width, height } = DefaultImage ?? {};
  const defaultImage = { src, width, height } as ImageEntry;

  const roleCategoryName = fmtCategoryNames(profileRoleSingle);
  const role = fmtRole(roleCategoryName, displayRoleMode, displayRole);

  return (
    <Card
      elevation={false}
      fullHeight
      colorSet="white"
      paper
      {...props}
      item={{
        href,
        honorific,
        firstName,
        lastName,
        description: role,
        email,
        phone: phoneMobile || phoneOffice,
        image: image ?? defaultImage,
      }}>
      <Card.Image ratio="landscape" transform="fullFit" />
      <Card.Body cx={{ pT: 'md' }}>
        <Card.Title variant="h5" as="span" {...TitleProps} />
        <Card.Description />
      </Card.Body>
      {(phoneMobile || phoneOffice || email) && (
        <Card.Foot cx={{ pT: '3xs' }}>
          <Card.Phone {...PhoneProps} />
          <Divider
            direction="vertical"
            margin="3xs"
            cx={{ color: 'accent' }}
            style={{ height: '1em' }}
          />
          <Card.Email {...EmailProps} />
        </Card.Foot>
      )}
    </Card>
  );
};
