import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import Video from '@/components/Video';
import Txt from '@/components/ui/Txt';
import * as styles from './VideoBlock.css';
import Grid from '@/components/ui/Grid';

const VideoBlock = createBlock<'blocks_video_BlockType'>(
  ({ videoUrl, caption, _blockMeta: meta, anchor: customAnchor }) => {
    return (
      <BlockContainer
        {...{
          meta,
          customAnchor,
          marginY: true,
          disableTransition: true,
          BlockLinkProps: { GridColProps: { offsetMd: 2, md: 8 } },
        }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <Video videoUrl={videoUrl} />
            <Txt as="figcaption" color="neutralDark" className={styles.caption}>
              {caption}
            </Txt>
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default VideoBlock;
