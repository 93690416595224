import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import Carousel from '@/components/Carousel';
import Image from '@/components/Image';
import Grid from '@/components/ui/Grid';

const ImageCarouselBlock = createBlock<'blocks_imageCarousel_BlockType'>(
  ({ children = [], _blockMeta: meta, anchor: customAnchor }) => {
    return (
      <BlockContainer
        {...{
          meta,
          customAnchor,
          marginY: true,
          disableTransition: true,
          BlockLinkProps: { GridColProps: { offsetMd: 2, md: 8 } },
        }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <Carousel
              items={children?.map((v, i) => (
                <Image
                  key={i}
                  alt=""
                  {...{
                    rounded: false,
                    ratio: 'landscape',
                    ...v.imageSingle,
                    caption: v.caption ?? undefined,
                  }}
                />
              ))}
            />
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default ImageCarouselBlock;
