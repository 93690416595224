import { REACT_PDF_VIEWER_WORKER_URL } from '@/lib/constants';
import { DocumentAsset } from '@/lib/parsers/common';
import { PageLayout, ScrollMode, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import Box from '../ui/Box';
import * as styles from './Document.css';
import Toolbar from './Toolbar';

type Document = {
  document: DocumentAsset | null | undefined;
};

export default function Document({ document }: Document) {
  const toolbarPluginInstance = toolbarPlugin({});

  const PAGE_MARGIN = 50;

  const pageLayout: PageLayout = {
    // Add margin for first page by manipulating the page css
    buildPageStyles: () => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }),
    // Add margins to all pages
    transformSize: ({ size }) => ({
      height: size.height + PAGE_MARGIN,
      width: size.width + PAGE_MARGIN,
    }),
  };

  return (
    document?.url && (
      <Worker workerUrl={REACT_PDF_VIEWER_WORKER_URL}>
        <Box className={styles.root}>
          <Toolbar toolbar={toolbarPluginInstance} />
          <Viewer
            fileUrl={document?.url}
            plugins={[toolbarPluginInstance]}
            scrollMode={ScrollMode.Horizontal}
            defaultScale={SpecialZoomLevel.PageFit}
            pageLayout={pageLayout}
          />
        </Box>
      </Worker>
    )
  );
}
