import { PresenterCard } from '@/components/EntryCard/EntryCard.Presenter';
import { useProfileCard } from '@/components/EntryCard/useProfileCard';
import { createBlock } from '@/lib/parsers/blocks';

export const ProfileCardBlock = createBlock<'blocks_presenterCard_BlockType'>(({ ...props }) => {
  const item = useProfileCard(props);

  if (!item) return null;

  return <PresenterCard item={item} />;
});

export default ProfileCardBlock;
