import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../../BlockContainer';

export const PreferenceManagerBlock = createBlock<'blocks_preferenceManager_BlockType'>(
  ({ _blockMeta: meta }) => {
    return (
      <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
        <Txt html>
          {`
            <div id="preference-manager-wrapper">
              <iframe aria-label="Hall & Wilcox Preference manager" id="preference-manager-form" class="concep_pm" style="border: none; margin: 0; height:1700px; width:100%;" src="https://hallandwilcoxia.concep.com/preferences/hwx/signup" scrolling="no">
              </iframe>
            </div>
            `}
        </Txt>
      </BlockContainer>
    );
  }
);

export default PreferenceManagerBlock;
